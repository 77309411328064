import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["emailInput", "suggestions", "enteredEmails", "emails", "error"]

  static values = {
    invalidEmailMessage: String
  }

  add(event) {
    if (event.keyCode === 13 || event.type === "click") {
      let enteredEmail = this.emailInputTarget.value?.toLowerCase()?.replace(/\s/g, "");
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (enteredEmail !== null && enteredEmail !== undefined && regex.test(enteredEmail)) {
        let selectInput = document.querySelector(".emails-input");
        let option = document.createElement('option');
        option.value = enteredEmail;
        option.selected = true;
        selectInput.add(option);

        this.enteredEmailsTarget
          .insertAdjacentHTML(
            "beforeend",
            `
              <div class="px-3 py-2 flex rounded bg-gray-200" data-id="${enteredEmail}" data-cy="entered-email">
                <span class="flex items-center flex-1">${enteredEmail}</span>
                <div class="w-8 h-8 flex justify-center items-center rounded cursor-pointer hover:bg-gray-300" data-id="${enteredEmail}" data-action="click->email#delete" data-cy="delete-email">
                  <i class="fas fa-times"></i>
                </div>
              </div>
            `);

        this.emailInputTarget.value = "";
      } else {
        this.errorTarget.innerHTML = this.invalidEmailMessageValue;
      }
    } else {
      this.errorTarget.innerHTML = "";
    }
  }

  delete(event) {
    const deletedItemId = event.currentTarget.dataset.id;
    if (deletedItemId !== null && deletedItemId !== undefined) {
      var selectInput = document.querySelector(".emails-input");
      var length = selectInput.options.length;
      for (var i = 0; i < length; i++) {
        if (selectInput.options[i].value == deletedItemId) {
          selectInput.options[i].remove();
        }
      }
      this.enteredEmailsTarget.querySelector(`[data-id="${deletedItemId}"]`).remove();
    }
  }

}